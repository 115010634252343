<template>
    <div class="text-center pt-10">
        <img alt="404" src="../assets/images/undraw_Lost_re_xqjt.svg" style="max-width: 100%;">
    </div>
</template>

<script>
export default {
    name: "Error404",
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>